<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <router-view></router-view>
  </div>
</template>

<script>
export default {

  created() {
    // this.$loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    // this.$http.defaults.headers.common['exjwtauthorization'] = this.$loginUserInfo?.token
  }
}
</script>
