<template>
  <div class="container mt-3 relative noselect" ref="" style="max-width:1300px;">
    <TopBar />
    <TopMenu />
    <TopBanner />
    <TopNotice />
  
    <!-- 상품리스트 12개-->
    <el-row :gutter="10" class="mt-3">
      <!-- $router.push('/productView/' + item.seq) -->
      <el-col :xl="3" :lg="3" :md="4" :sm="4" :xs="8" v-for="(item, index) in tmpTableData" :key="index"
        @click.native="selectItem=item" class="carousel-container ">
        <productcard :data="item" />
      </el-col>
    </el-row>
    <productviewpop v-if="selectItem" :data="selectItem" @callback="productviewpopcallback" />
    <div class="bodybanner hand" @click="$changeRoute('epc')"></div>
  
    <!-- 서브리스트 12개씩 좌우 마우스로 드래그-->
    <div v-for="(items, keys) in subList" :key="keys" class="mt-3">
      <el-row>
        <el-col :xs="24" :xl="24" :sm="24">
          <div class="font18 bold borderbottom3px-orange">{{ keys }}</div>
        </el-col>
        <!-- <el-col :xs="16" :xl="16" :sm="16" class="text-right d-block d-md-none colorgraylight">
          <i class="el-icon-back"></i>
          좌우로 드래그하세요.
          <i class="el-icon-right"></i>
        </el-col> -->
      </el-row>
      <div class="parent-div">
        <!-- <div class="drageinfo d-none d-md-block">
          <div class="noselect">
            <i class="el-icon-back"></i>
            마우스를 좌우로 드래그하세요.
            <i class="el-icon-right"></i>
          </div>
        </div> -->
        <el-row :span="8">
          <el-col :span="8" :xl="3" :md="3" v-for="(item, index) in items" :key="index">
            <productcardbutton :data="item" />
          </el-col>
        </el-row>
        <!-- <carousel class="carousel-container" :loop="true" :perPageCustom="[[480, 1], [768, 2], [500, 3]]"
          :class="{ 'mobile-scroll': $isMobile }" style="max-width:1300px;">
          <slide v-for="(item, index) in items" :key="index" :loop="true">
            <productcardbutton :data="item" />
          </slide>
        </carousel> -->
      </div>
    </div>
    <FootBanner class="mb-5" />
    <FootBar />
    <el-backtop>
    </el-backtop>
  </div>
</template>

<script>
import TopBar from './component-top.vue'
import FootBanner from './component-foot-banner.vue'
import TopBanner from './component-top-banner.vue'
import TopNotice from './component-top-notice.vue'
import TopMenu from './component-top-menu.vue'
import FootBar from './component-foot.vue'
import productcard from './component-product-card.vue'
import productcardbutton from './component-product-card-button.vue'
import productviewpop from './component-product-view-pop.vue'
import { Loading } from 'element-ui'
export default {
  components: {
    TopBar, FootBar, TopBanner, TopNotice, TopMenu, Loading, productcard, productcardbutton, FootBanner, productviewpop
  },
  data() {
    return {
      selectItem: null,
      req: {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 16
      },
      tmpTableData: [],
      subList: [],
      totalCount: 0,
      loginUserInfo: null,
    };
  },
  beforeDestroy() {
  },

  async created() {
    window.scrollTo(0, 0);
    this.loading = Loading.service({ fullscreen: true })
    this.loginUserInfo = JSON.parse(localStorage.getItem('loginUserInfo'))
    this.$http.defaults.headers.common['exjwtauthorization'] = this.loginUserInfo?.token
    await this.read()
    await this.readSubList()
  },
  methods: {
    productviewpopcallback(data) {
      this.selectItem = null
    },
    async read() {
      await this.$http.post(`${this.$serverurl}/api/getMallProductInfo`, this.req)
        .then(res => {
          if (200 == res.data.code) {
            this.tmpTableData = res.data.body.results
            this.totalCount = res.data.body.total
          }
        })
      this.$overay(this.loading)
    },
    async readSubList() {
      const req = {
        objSearchMapper: {
          searchKeyword: null,
        },
        page: 1,
        size: 24
      }
        await this.$http.post(`${this.$serverurl}/api/getMallProductInfoForCategory`, req)
          .then(res => {
            if (200 == res.data.code) {
              this.subList= res.data.body.results 
            }
          })
    },
    setbackground(item) {
      if (item && item.images && item.images.length > 0) {
        return {
          background: `url(${this.$imageurl}${item.images[0]}) no-repeat center center / cover !important`
        };
      } else {
        return {
          background: `url('${this.$imageurl}/upload/no-image.webp') no-repeat center center / cover`
        };
      }
    },

    handleClick(seq) {
      this.$router.push('/productView/' + seq)
    },


  },
}
</script>

<style>
.mobile-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.carousel-container .VueCarousel-inner {
  max-width: 335px;
}

.carousel-container .VueCarousel-slide {
  width: 140px;
  margin: 5px;
}

.carousel-container .VueCarousel-pagination .VueCarousel-dot-container {
  margin: 0px !important;
}

.carousel-container .VueCarousel-pagination .VueCarousel-dot-container .VueCarousel-dot {
  margin: 0px !important;
}

@media (max-width: 767px) {
  .carousel-container .productView {
    min-width: 90px;
  }

  .carousel-container .VueCarousel-inner {
    max-width: 198px;
  }

  .carousel-container .VueCarousel-slide {
    max-width: 100px;
    margin: 5px;
  }

  .carousel-container .VueCarousel-dot-container {
    display: none !important;
  }
}

@media (min-width: 768px) {

  /* // 일반화면 */
  .mobile-scroll {
    overflow-x: auto;
  }
}
</style>
