<template>
  <div>
    <div v-if="!checkData(data)">
      데이터 로딩중입니다...
    </div>
    <el-row v-if="checkData(data) && getCarsTagsList">
      <el-col :xl="24" :md="24" :xs="24">
        <table class="table-params w100p table-hover" @mouseout="setBgPnum = ''">
          <tbody>
            <tr>
              <th style="width: 50px;" class="text-center">좌표</th>
              <th style="width: 100px;">정품품번</th>
              <th>품명</th>
              <th style="width: 50px;" class="text-right">장착수</th>
              <th style="width: 70px;" class="text-center">호환품번</th>
              <th style="width: 80px;" class="text-right">가격</th>
              <th style="width:70px;" class="text-center">구매요청</th>
            </tr>
            <!-- :class="{ 'highlighted-row': localselectedItemIndex === item[0].pnum || data.hotNameInfo && item[0].pid === data.hotNameInfo.query, ['pnum' + item[0].pnum]: true }"
                                                @click="selectRow(item[0].pnum)" @mousemove="moveRow(item[0].pnum)" -->
            <tr v-for="(item, index) in data.part_detail" :key="index"
              :class="{ 'highlighted-row': localselectedItemIndex === item[0].pnum, ['pnum' + item[0].pnum]: true,'colorred':item[0].colorvalue==0 }"
              class="relative" @click="selectRow(item[0])" @mousemove="moveRow(item[0])">
              <td class="text-center">
                {{ item[0].itid }}
              </td>
              <td>
                <div v-html="formatHotName(item[0].pid, hotNameData)"></div>
              </td>
              <td>
                {{ $convert_br(item[0].label) }}
                <span class="colorred bold hand" v-if="item[0].refmid &&item[0].refmid.length>0"
                  @click="returnCallbackSikp(item[0].refmid[0] )">
                  {{ item[0].refmid[0] }}
                </span>
                <div>
                  {{ $convert_br(item[0].model) }}
                </div>
                <div>
                  {{ $convert_br(item[0].remark) }}
                </div>
              </td>
              <td class="text-right">
                <!-- 장착수량 -->
                {{ $convert_br(item[0].quantity) }}
              </td>
              <td>
                <!-- 호환품번 -->
                <div v-if="checkParts2Tags(item[0] && item[0].pid)" class="text-center">
                  <el-popover placement="bottom" trigger="click" :content="lastReplaceList">
                    <el-button size="mini" class="badge hand el-btn-red" slot="reference"
                      @click="getCarsReplace(brand, item[0].pid)">호환품번</el-button>
                  </el-popover>
                </div>
                <div v-else>
                  -
                </div>
              </td>
              <td class="text-right" :style="item[0].salePrice > 0 ? 'background-color: rgba(200, 255, 0, 1)' : ''">
                <!--판매가격-->
                <span class="p-1 text-bold">
                  {{ $totalInTax(item[0].salePrice, 1, true) }}
                </span>
              </td>
              <td class="text-center">
                <span class="badge badge-green hand" v-if="item[0].inquiryInfo" @click="showQnaView(item[0])">
                  QNA정보
                </span>
                <span class="badge badge-blue hand"
                  v-else-if="item[0].pid && item[0].pid != '-' && !item[0].salesProductInfo.length"
                  @click="boardContent.item = item[0]; showQnaRegWindow()">
                  구매요청
                </span>
                <span class="badge badge-green hand" v-if="item[0].salesProductInfo.length" @click="productView(item[0])">
                  판매정보
                </span>
  
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <span v-if="!data">로딩중입니다...</span>
    <!-- <json-viewer :value="data" :expand-depth=1 copyable boxed sort class="mt-3"></json-viewer> -->
    <epcQna :switch="isShowDrag" :boardContent="boardContent" @callback="epcQnaCacllback" />
    <epcQnaView :switch="isShowQnaDrag" :qnaViewData="qnaViewData" @callback="epcQnaCacllback" />
  </div>
</template>
<script>

import epcQna from 'src/pages/Dashboard/Pages/component-epc-qna.vue'
import epcQnaView from 'src/pages/Dashboard/Pages/component-epc-qna-view.vue'
export default {
  name: 'partsgroup3',
  components: { epcQna, epcQnaView },
  data() {
    return {
      hotNameData: '',
      qnaViewData: {},
      isShowDrag: false,
      isShowQnaDrag: false,
      getCarsTagsListData: {},
      // 테이블 선택 양식관련
      boardContent: {
        title: '',
        content: '',
        // item: {},
        contentSub: {
          modelName: null,
          vin: null,
          name: null,
          pid: null,
          quantity: null,
        },
        category: 0,
        type: 1,
      },
      setBgPnum: '',
      localselectedItemIndex: '',
      lastReplaceList: '로딩중입니다...',
      filterData: false,

      // hotNameInfo:{},

    }
  },
  async created() { },
  props: ['data', 'getCarsTagsList', 'selectedItemIndex', 'brand', 'getCarsVinList', 'vin', 'hotName', 'filter'],


  watch: {
    filter: {
      deep: true,
      immediate: true,
      async handler(cancelFilter, oldValue) {
        const detailList = []
        if (!cancelFilter) { // 필터적용
          this.data.part_detail.map(main => {
            main.map(sub => {
              if (sub.colorvalue == 1) {
                detailList.push(main)
              }
            })
          })
          this.data.part_detail = detailList
        }
      }
    },
    hotName: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.hotNameData = newValue
      }
    },
    data: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
      }
    },
    getCarsTagsList: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.getCarsTagsListData = newValue
      }
    },
    selectedItemIndex: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        this.localselectedItemIndex = newValue
      }
    },
    brand: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        // console.log(newValue)
      }
    },
    getCarsVinList: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        // console.log(newValue)
      }
    },
    vin: {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
      }
    },

  },
  methods: {
    formatHotName(origin, hotName) {
      const regex = new RegExp(hotName, 'gi');
      const highlightedText = origin.replace(regex, `<span style="background-color: yellow; color: black;">$&</span>`);
      return highlightedText;
    },
    epcQnaCacllback(data) {
      this.isShowDrag = data
      this.isShowQnaDrag = data
    },
    showQnaRegWindow() {
      // return
      this.boardContent.contentSub.modelName = this.getCarsVinList?.data?.model_name
      this.boardContent.contentSub.vin = this.vin
      this.boardContent.contentSub.name = this.$convert_br(this.boardContent?.item?.label)
      this.boardContent.contentSub.pid = this.boardContent?.item?.pid
      this.boardContent.contentSub.quantity = this.boardContent?.item?.quantity
      this.isShowDrag = true
    },
    showQnaView(item) {
      // return
      this.qnaViewData = item.inquiryInfo
      this.isShowQnaDrag = true
    },
    productView(partsItem) {
      let itemPid = this.brand == 'benz' && partsItem.spid.startsWith('A') ? partsItem.spid.slice(1) : partsItem.spid
      if (partsItem.salesProductInfo.length > 1) { // 상품여러개
        this.$changeRoute('searchCenter/' + itemPid, 'new')
        // this.$changeRoute('searchCenter/' + partsItem.salesProductInfo[0].pid, 'new')
      } else { // 단일상품
        this.$changeRoute('productView/' + partsItem.salesProductInfo[0].seq, 'new')
      }
    },
    async returnCallbackSikp(mid) {
      this.$emit('callback', { skip: true, mid })
    },
    async returnCallback(item) {
      this.$emit('callback', item)
    },
    getCatetoryImg(params) {
      if (!params) {
        return 'images/no-image.webp'
      }
      return `${params}`
    },

    async getCarsReplace(brand_code, pid) {
      this.lastReplaceList = '로딩중입니다...'
      const tmphotNameInfo = JSON.parse(localStorage.getItem(pid + 'hotNameInfo'))
      if (tmphotNameInfo) {
        this.getCarsReplaceList = tmphotNameInfo
        this.lastReplaceList = this.getCarsReplaceList.join(', ')
        return
      }
      this.getCarsReplaceList = [] // 검색된 호환품번 초기화
      const baseURL = `${this.$serverurl}/api/getCarsReplace`
      const data = {
        brand_code,
        pid
      }
      try {
        const response = await this.$http.post(baseURL, data);
        const result = JSON.parse(JSON.stringify(response))
        const res = result.data
        if (res) {
          if (res.code == '200') {
            this.getCarsReplaceBackupList = JSON.parse(JSON.stringify(res.body.data))
            this.getCarsReplaceList = JSON.parse(JSON.stringify(res.body.data))
            localStorage.setItem(pid + 'hotNameInfo', JSON.stringify(this.getCarsReplaceList))
            this.lastReplaceList = this.getCarsReplaceList.join(', ')
            if (!this.lastReplaceList) { this.lastReplaceList = '준비중입니다.' }

          } else {
            localStorage.removeItem(pid + 'hotNameInfo')
            alert(res.msg)
          }
        } else {
          alert('정보가 없습니다.')
        }
      } catch (error) {
        console.error(error)
      }
    },

    checkParts2Tags(pid) { // 호환품번여부
      const returnValue = Object.values(this.getCarsTagsList).filter(item => {
        return item.pid === pid && item.isreplace === 1
      }).length > 0
      return returnValue
    },
    getPrice(params) {
      if (params.price) {
        return params.price
      } else {
        return 0
      }
    },

    // 테이블 양식관련
    async selectRow(item) {
      const tmpData = JSON.parse(JSON.stringify(item))
      tmpData.vin = this.vin
      tmpData.brand = this.brand
      const baseURL = `${this.$serverurl}/api/mallSearchEpcPartsSet`
      await this.$http.post(baseURL, [[tmpData]]).then(
        res => { })

      const pnum = item.pnum
      this.returnCallback(item)
      if (this.findMove) { return }
      this.localselectedItemIndex = pnum == this.localselectedItemIndex ? '' : pnum;
      // 행선택 배경레드
      const ttt = this.$refs['position' + pnum];
      if (!ttt) { return false }
      // 배열이 아닌 직접적인 요소를 가져옴.
      const targetElement = Array.isArray(ttt) ? ttt[0] : ttt;
      if (targetElement instanceof HTMLElement) {
        targetElement.classList.add('select-red');
      }
    },
    // 좌표버튼이벤트
    click_hover(hsKey) {
      const isExist = this.getCarsPartsList.part_detail.filter((item) => {
        return item[0].pnum == hsKey
      })
      if (isExist && isExist.length == 0) {
        alert('해당차량에 적합하지않는 제품입니다.')
        return
      }
      this.localselectedItemIndex = hsKey
    },
    moveRow(item) {
      this.$emit('callback', item)
      this.setBgPnum = item.pnum
    },
    checkData(item) {
      if (item?.part_detail?.length) {
        return true
      } else {
        return false
      }
      // 서버에 부품검색통계등록요청
    }
  },

}

</script>

<style scoped>
.highlighted-row {
  background: rgba(245, 242, 200, 0.8);
}
</style>